import React from 'react'
import {createAccount} from '../../backend'
import {CreateAccountForm} from '../../components'
import {MainLayout} from '../../layout'
import {navigate} from '../../utils'
import {useAuth} from '../../hooks'

export default function CreateAccount() {
  const {setCredentials} = useAuth()

  const handleCreateAccount = async (params) => {
    await createAccount(params)
    setCredentials(params)
    navigate(`/auth/confirm-phone?phone=${encodeURIComponent(params.phone)}`)
  }

  return (
    <MainLayout>
      <CreateAccountForm onCreateAccount={handleCreateAccount}/>
    </MainLayout>
  )
}
